import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

class TrademarksPage extends React.Component {
  componentDidMount() {
    // Replacing &reg; elements
    let regElements = document.querySelectorAll("main p, main .btn, main dt, main dd"); 
    regElements.forEach(function(el) {
      el.innerHTML = el.innerHTML.replace('®', "<sup>&reg;</sup>");
    })
  }
  render() {
    return (
      <Layout bodyClass="privacy-page">
        <Seo title="Trademarks" description="AMVAC Chemical Corporation is a wholly owned subsidiary of American Vanguard Corporation. All rights reserved. AMVAC, AZTEC, COUNTER, SIMPAS, SIMPAS-applied Solutions, SaS, SmartBox, SmartBox+ SmartCartridge, SmartChoice, Smart Tag, Thimet, Ultimus, ZINC and respective logos are trademarks owned by AMVAC Chemical Corporation" />
        <section className="padding hero">
          <div>
            <h1>Trademarks</h1>
          </div>
        </section>

        <section>
          <div className="container flow">
              <p>AMVAC Chemical Corporation is a wholly owned subsidiary of American Vanguard Corporation. All rights reserved. AMVAC, AZTEC, COUNTER, iNvigorate, SIMPAS, SIMPAS-applied Solutions, SaS, SmartBox, SmartBox+, SmartCartridge, SMARTCHOICE, Smart Tag, Thimet, ULTIMUS, UltimusAg, ZINC and respective logos are trademarks owned by AMVAC Chemical Corporation.</p>
              <p>AgVeritas is a trademark of XSInc. Force is a trademark of Syngenta Group Company. microSync IRON IDC is a trademark of Verdesian Life Sciences. Ridomil Gold is a registered trademark of Syngenta Corporation.</p>
              <p>AZTEC, COUNTER, Force, SMARTCHOICE and Thimet are restricted use pesticides. Important: Always read and follow label instructions. See label for listed pests. Some products may not be registered for sale or use in all states or counties. Please check with your state agency responsible for pesticide registration to ensure registration status. All products are EPA registered.</p>
              <p>Case IH is a trademark registered in the United States and many other countries, owned by or licensed to CNH Industrial N.V., its subsidiaries or affiliates. Great Plains is a trademark of Great Plains Manufacturing, Inc. John Deere is a registered trademark of Deere & Company. Kinze is a registered trademark of Kinze Manufacturing, Inc. Trimble and the Globe & Triangle logo are trademarks of Trimble Inc., registered in the United States and in other countries. White Planters is a trademark of AGCO Corporation.</p>
              <a href="https://www.amvac.com/trademark-guidelines" target="_blank" rel="noopener noreferrer">AMVAC Trademark Guidelines</a>
          </div>
        </section>
      </Layout>
    )
  }
}
export default TrademarksPage
